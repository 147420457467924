import Axios from 'axios';
import { post } from '@/utils/network/axios-helper';
import { AxiosResponseType } from '@/utils/network/axios-helper';

export interface IUserInfoApiResData {
  avatar: string;
  exam_type: number;
  head_sculpture: string;
  name: string;
  mobile: string;
  wechat_name: string;
  // 1 删除 2 正常
  is_delete: number;
  is_guide: number;
  country_id: number;
  // writing vip
  vip_days: number;
  vip_level: number;
  vip_text: string;
  expiration_time: number;
  // reading vip
  reading_vip_level: number;
  reading_vip_days: number;
  reading_expiration_time: number;
  // listening vip
  listening_vip_days: number;
  listening_expiration_time: number;

  // 考试时间
  exam_date: number;
  // 考试目的
  exam_purpose: number;
  // 目标分数
  target_score: number;
  target_score_listen: number;
  target_score_speak: number;
  target_score_read: number;
  target_score_write: number;
  // 是否参加过雅思 1是 2否 0未知
  is_ielts: 0 | 1 | 2;

  // ai free try time
  ai_test_num: number;
  account: string;
}

export function captchaCreate() {
  return post<{
    id: string;
    // image base64 string
    captcha: string;
  }>('/api/captcha/create', {});
}

export interface CountryItem {
  id: number;
  name: string;
  code: string;
}

// 获取国家列表
export function getCountryList() {
  return post<{
    list: CountryItem[];
  }>('/api/common/get-country-list', {});
}

export function captchaVerify(id: string, code: string) {
  return post<{
    result: boolean;
  }>('/api/captcha/verify', {
    id, code
  });
}

export const getUserInfoApi = async () => {
  return post<IUserInfoApiResData>('/api/student/get-info', {}, { needAuth: true });
};

export const updateUserInfoApi = async (data: Partial<IUserInfoApiResData>) => {
  return post('/api/student/update-info', data, { needAuth: true });
};

export const getFreeTry = async () => {
  return post('/api/student/receive-approval', {});
};

export const checkToken = async (): Promise<AxiosResponseType<{ is_expire: number; token: string; }>> => {
  return post('/api/student/check-token', {});
};

export function getWechatQrcodeState() {
  return post<{
    redirect_uri: string;
    code: string;
    app_id: string;
  }>('/api/student/get-wechat-bind');
}

export function getWechatQrcodeStatus(code: string) {
  return post<{
    result: 'success' | 'failed' | 'exist';
  }>('/api/student/get-wechat-bind-authorize', {
    code
  }, { needAuth: true });
}

export const postVoucher = async (code: string): Promise<AxiosResponseType<{
  code: number;
  msg: string;
}>> => {
  return Axios.post('/api/student/use-voucher', {
    code
  });
};

export function sendSMS(phone?: string, countryId?: number, value?: string, code?: string) {
  return post<{
    result: boolean;
    captcha: string;
    captcha_code: string;
  }>('/api/student/send-student-sms', {
    country_id: countryId || 1,
    mobile: phone,
    captcha_value: value,
    captcha_code: code,
  });
}

// 验证短信验证码
export const checkSMS = async (code: string, countryId: number, mobile?: string): Promise<AxiosResponseType<{
  code: number;
  msg: string;
}>> => {
  return Axios.post('/api/student/check-sms', {
    code,
    mobile,
    country_id: countryId
  });
};

export function checkBindMobileSMS(phone: string, countryId: number, code?: string) {
  return post<{
    result: boolean;
  }>('/api/student/account-bind-mobile', {
    mobile: phone,
    country_id: countryId,
    code,
  });
}

export interface IPermissionList {
  is_coach: boolean; // 写作课练习
  is_review: boolean; // 写作可测评
  is_essay: boolean; // 生成范文
  is_approval: boolean; // 是否试用
  is_teacher: boolean; // 是否老师
}

// 获取权限
export const getPermissions = async () => {
  return post<IPermissionList>('/api/student/get-operation-permissions-list', {});
};

// 退出
export const logout = async () => {
  return Axios.post('/api/student/logout');
};


// 星芒图

export interface IPersonalAbilityItem {
  gra_pattern: number;
  gra_syntax: number;
  cc: number;
  lr: number;
  tr: number;
}

export interface IPersonalAbilityRes {
  list: IPersonalAbilityItem[];
}

export const personalAbility = async (): Promise<AxiosResponseType<IPersonalAbilityRes>> => {
  return Axios.post('/api/exercise/get-evaluation-score-statistics', {});
};

/**
 * 分数历史
 */
export interface IPersonalEvaluationHistoryRes {
  list: number[];
}

export const personalEvaluationHistory = async (): Promise<AxiosResponseType<IPersonalEvaluationHistoryRes>> => {
  return Axios.post('/api/exercise/get-evaluation-score-list', {});
};

/**
 * 评分记录
 */

export interface IFeedbackItem {
  create_date: string;
  duration: string;
  exercises_title: string;
  id: number;
  score: number;
  type: number; // 1练习 2测评
}

export interface IPersonalFeedbackHistoryRes {
  list: IFeedbackItem[];
}

export const personalFeedbackHistory = async (): Promise<AxiosResponseType<IPersonalFeedbackHistoryRes>> => {
  return Axios.post('/api/exercise/get-study-record-list', {});
};

/**
 * 范文列表
 */
export interface ISampleItem {
  category_name: string;
  create_date: string;
  exercises_title: string;
  id: number;
}

export interface IPersonalSampleTextHistoryRes {
  list: ISampleItem[];
}

export const personalSampleTextHistory = async (): Promise<AxiosResponseType<IPersonalSampleTextHistoryRes>> => {
  return Axios.post('/api/exercise/get-sample-text-list', {});
};

/**
 * 重置密码发送短信
 * @param phone 
 * @param countryId 
 * @param value 
 * @param code 
 * @returns 
 */
export function sendResetPasswordSMS(phone?: string, countryId?: number, value?: string, code?: string) {
  return post<{
    success: boolean;
    captcha: string;
    captcha_code: string;
  }>('/api/student/reset-password-send-sms', {
    country_id: countryId || 1,
    mobile: phone || '',
    captcha_value: value,
    captcha_code: code,
  });
}
/**
 * 重置密码检查短信
 * @param phone 
 * @param countryId 
 * @param code 
 * @returns 
 */
export function checkResetPasswordSMS(phone?: string, countryId?: number, code?: string) {
  return post<{
    result: boolean;
  }>('/api/student/check-reset-password-sms', {
    mobile: phone || '',
    country_id: countryId || 1,
    code,
  });
}
/**
 * 重置密码
 * @param phone 
 * @param countryId 
 * @param password 
 * @param submit_password 
 * @returns 
 */
export function resetPassword(phone: string, countryId: number, password: string, submit_password: string) {
  return post<{
    result: boolean;
  }>('/api/student/reset-password', {
    mobile: phone,
    country_id: countryId,
    password,
    submit_password
  });
}