/* eslint-disable indent */
import React, { FC } from 'react';
import { Button, ButtonProps } from 'antd';
import classnames from 'classnames';
import css from './simple.button.module.less';

export interface SimpleButtonProps extends ButtonProps {
  source?: string;
  duyan_type?: 'standard' | 'dashed' | 'ai' | 'ai-default' | 'ai-primary' | 'ai-danger' | 'ai-design' | 'ai-design-cutout' | 'write-next' | 'normal';
}

export type SimpleButtonType = 'link' | 'text' | 'default' | 'primary' | 'dashed' | undefined

export const SimpleButton: FC<SimpleButtonProps> = ({ type = 'text', duyan_type = '', ...props }) => {
  const restProps = { type, ...props };

  let simpleButtonStyle = '';
  switch (type) {
    case 'primary': {
      simpleButtonStyle = css.simpleButtonPrimary;
      break;
    }
    case 'default': {
      simpleButtonStyle = css.simpleButtonText;
      break;
    }
    default: {
      simpleButtonStyle = '';
    }
  }


  let duyanButtonStyle = '';
  switch (duyan_type) {
    case 'standard': {
      duyanButtonStyle = css.duyanButtonStandard;
      break;
    }
    case 'dashed': {
      duyanButtonStyle = css.duyanButtonDashed;
      break;
    }
    case 'ai': {
      duyanButtonStyle = css.duyanButtonAI;
      break;
    }
    case 'ai-default': {
      duyanButtonStyle = css.duyanButtonAIDefault;
      break;
    }
    case 'ai-primary': {
      duyanButtonStyle = css.duyanButtonAIPrimary;
      break;
    }
    case 'ai-danger': {
      duyanButtonStyle = css.duyanButtonAIDanger;
      break;
    }
    case 'ai-design': {
      duyanButtonStyle = css.duyanButtonAIDesign;
      break;
    }
    case 'ai-design-cutout': {
      duyanButtonStyle = css.duyanButtonAIDesignCutout;
      break;
    }
    case 'write-next': {
      duyanButtonStyle = css.duyanButtonWriteNext;
      break;
    }
    case 'normal': {
      duyanButtonStyle = css.simpleButtonNormal;
      break;
    }
    default: {
      duyanButtonStyle = '';
    }
  }

  return <Button id={props.id} {...restProps} className={classnames(css.simpleButton, props.className && props.className, simpleButtonStyle, duyanButtonStyle)} />;
};